import img from "../assets/images/project.png";
import React from "react";

const Projects = () => {
  return (
    <section className="about-three">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <p className="about-two__text">
              Kids World Education Consultants, has established its presence
              Pan-India through the implementation of its curriculum and a host
              of other services pertaining to early childhood education. We
              earnestly thank the educators and institutions that have partnered
              with us, in this journey across the country. We are currently,
              available throughout India at the following locations and are
              constantly looking to expand our presence thereby, transforming
              the lives of many more young learners! Contact Us to find out our
              partner institutions nearest to you or if you wish to become one!
            </p>
          </div>
          <div className="col-xl-6">
            <img src={img} alt="" width="80%" />
          </div>
          <div className="col-xl-12">
            <div className="text-center"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
